import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../css/navbar.module.css';
import '../css/slickStyles.css';

const Gallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides on desktop
    slidesToScroll: 3, // Scroll 3 slides at a time on desktop
    responsive: [
      {
        breakpoint: 768, // Set a breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1, // Scroll 1 slide at a time on mobile
        },
      },
    ],
  };

  return (
    <div id='gallery' style={{ backgroundColor: 'black', color: 'white' }}>
      <hr className='text-white w-80 m-auto py-4' />
      <h1 className={`${styles.nav_title} text-white text-lg text-center`}>Glimpse</h1>
      <div className='px-6 mx-2'>
        <Slider {...settings}>
          <div className='pt-6'>
              <img alt="peculiar beats famous dj of belgaum number 1 dj" src={require('../images/peculiar-1.JPG')}/>
          </div>
          <div className='pt-6'>
              <img alt="peculiar beats famous dj of belgaum belagavi dj karnataka famous djs" src={require('../images/peculiar-3.JPEG')}/>
          </div>
          <div className='pt-6'>
              <img alt="peculiar beats famous dj of belgaum" src={require('../images/peculiar-4.JPEG')}/>
          </div>
          <div className='pt-6'>
              <img alt="peculiar beats famous dj of belgaum" src={require('../images/peculiar-2.JPG')}/>
          </div>
          <div className='pt-6'>
            <img alt="peculiar beats famous dj of belgaum" src={require('../images/peculiar-beats-8.jpg')}/>
          </div>
          <div className='pt-6'>
            <img alt='Belgaum famous DJ peculiar beats' src={require('../images/peculiar-beats-5.jpg')} />
          </div>
          <div className='pt-6'>
            <img alt='Belgaums famous DJ peculiar beats' src={require('../images/peculiar-beats-1.jpg')} />
          </div>
          <div className='pt-6'>
            <img alt='Belagavi famous DJ peculiar beats' src={require('../images/peculiar-beats-7.jpg')} />
          </div>
          <div className='pt-6'>
            <img alt='Bgm famous DJ peculiar beats' src={require('../images/peculiar-beats-3.jpg')} />
          </div>
          <div className='pt-6'>
            <img alt='Belgav famous DJ peculiar beats' src={require('../images/peculiar-beats-2.jpg')} />
          </div>
          <div className='py-6'>
            <img alt='Belgaum famous DJ peculiar beats' src={require('../images/peculiar-beats-4.jpg')} />
          </div>
        </Slider>
      </div>
      <p className='md:text-lg text-sm leading-6 pb-6 px-6'>
        Whether you're looking for a DJ for your event,
        Peculiar Beats has the expertise to create an ambiance that's tailored to your needs.
        From smooth and mellow sounds to high-energy beats, Peculiar Beats can set the tone for any occasion.
        With state-of-the-art equipment and a keen ear for detail, you can rest assured that your event will be a success.
        Contact us today by clicking the contact sources below to book Peculiar Beats for your next event and get ready to experience the magic of music like never before!
      </p>
      <hr className='text-white w-80 m-auto' />
    </div>
  );
};

export default Gallery;
