import React from 'react'
import styles from '../css/navbar.module.css'

const Contact = () => {
  return (
  <div id='contact' className={`text-sm`} style={{backgroundColor:"black", color: "white"}}>
    <hr className='text-white w-80 m-auto'/>
    <div className='grid md:grid-cols-2 grid-cols-1 gap-4 px-6 py-4'>
      <div className='grid grid-cols-2'>
        <h2 className={`${styles.nav_title} col-span-5 pb-3 text-center`}>Listen Muisc Sets</h2>
        <a href='https://www.mixcloud.com/peculiar_beats/' target={"_blank"} className='p-2'>
          <img src={require('../images/mixcloud.png')} alt="" />
        </a>
        <a href='https://soundcloud.com/peculiar-beats' target={"_blank"} className='p-2'>
          <img src={require('../images/soundCloud.jpg')} alt="" />
        </a>
      </div>
      <div className='grid grid-cols-4' style={{width: "75%", margin:"auto"}}>
        <h2 className={`${styles.nav_title} col-span-5 text-center py-4`}>Book Now!</h2>
        <a href='https://www.instagram.com/peculiar_beats/' target={"_blank"} className='p-2'>
          <img src={require('../images/instagram.png')} style={{width:"75px", margin:"auto"}} alt="" />
        </a>
        <a href="https://wa.me/917619437950" target={"_blank"} className='p-2'>
          <img src={require('../images/whatsapp.png')} style={{width:"75px", margin:"auto"}}  alt="" />
        </a>
        <a href="tel:+917619437950" target={"_blank"} className='p-2'>
          <img src={require('../images/telephone.png')} style={{width:"75px", margin:"auto"}}  alt="" />
        </a>
        <a href = "mailto: peculiarbeats@gmail.com" target={"_blank"} className='p-2'>
          <img src={require('../images/email.png')} style={{width:"75px", margin:"auto"}}  alt="" />
        </a>
      </div>
    </div>
    <hr className='text-cyan-700 w-full'/>
    <div className='flex flex-col md:flex-row p-4 text-xs text-center'>
      <div className='md:w-1/3'>
        <a href="tel:+917619437950" target={"_blank"}>Phone: +917619437950</a>
      </div>
      <div className='md:w-1/3 md:py-0 py-2'>
        <a href = "mailto: peculiarbeats@gmail.com" target={"_blank"}>Email: peculiarbeats@gmail.com</a>
      </div>
      <div className='md:w-1/3'>
        <p>Address: Belagavi, Karnataka, 590003</p>
      </div>
    </div>
    <div className='text-white text-xs p-4 text-center'>©2023 - peculiarbeats.com all rights reserved</div>
  </div>
  )
}

export default Contact