import React, { useEffect, useState }  from 'react'
import styles from '../css/home.module.css'

const Home = () => {
  return ( 
    <div id='home' className={`${styles.homeBackground} md:pt-24 pt-20 pb-6 w-full`}>
      <div className='md:text-lg text-sm leading-6 text-white px-6'>
            <p>
              Welcome to the electrifying world of Peculiar Beats, 
              where music is not just an art but a lifestyle. 
              As one of the most sought-after DJs in the industry, 
              Peculiar Beats has established a reputation for delivering unforgettable 
              experiences through his exceptional music and unmatched talent. 
              With an extensive collection of music spanning across genres and eras, 
              Peculiar Beats knows how to keep the crowd moving and the party going.
            </p><br className='md:block hidden'/>
      </div>
        <div className={`${styles.introContainer} items-center px-6`}>
          <div className={`md:text-lg text-white text-sm leading-6 w-full mb-4`}>
            <p>
              As a versatile DJ with a passion for music, Peculiar Beats can cater to any audience and event. 
              Whether you're planning a wedding, corporate event, or a private party, 
              Peculiar Beats can provide the perfect soundtrack to keep your guests dancing all night long. 
              From old-school classics to the latest hits, Peculiar Beats has a diverse music 
              collection that's sure to please everyone.
            </p><br className='md:block hidden'/>
            {/* <div className={`w-full py-4 md:hidden block`}>
              <img src={require('../images/peculiar-beats-8.jpg')} alt="peculiar beats famous dj of belgaum"/>
            </div>
            <p>
              But Peculiar Beats is more than just a DJ - he's an entertainer. 
              With his engaging personality and natural charisma, Peculiar Beats 
              knows how to connect with his audience and create an unforgettable experience. 
              He can also provide lighting and other special effects to take your event to the next level.</p>
            <p><br/> */}
            {/* <p>
              Don't settle for an average DJ - choose Peculiar Beats for an extraordinary event that your guests 
              will be talking about for years to come. Contact us today to book Peculiar Beats and let the music do the talking!
            </p> */}
          </div>
          <div className={`${styles.myPic} w-full py-4 md:pl-4`}>
            <img src={require('../images/gallery-6.jpg')} alt="belgaum's famous dj peculiar beats" />
          </div>
        </div>
    </div>
  )
}

export default Home;