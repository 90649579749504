import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../css/navbar.module.css';

const logos = [
    'brands/Fern-Hotels.jpg', // Replace with the actual image filenames
    'brands/brand-2.jpg',
    'brands/orchard.jpg', 
    'brands/Insomnia-Logo.png',
    'brands/brand-1.jpg',
    'brands/brand-3.jpg',
    'brands/brand-4.jpg', 
    // Add more image filenames as needed
  ];

const Brands = () => {
  const settings = {
    dots: false, // Hide the dots if you want
    infinite: true,
    speed: 3000, // Adjust the speed of the carousel (2 seconds per slide in this example)
    slidesToShow: 6, // Show one logo at a time
    slidesToScroll: 1, // Scroll one logo at a time
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 0, // Set the autoplaySpeed to 0 for smooth continuous sliding
    cssEase: 'linear', // Use linear easing for smoother auto-scrolling
    responsive: [
        {
          breakpoint: 768, // Set a breakpoint for mobile devices
          settings: {
            slidesToShow: 2, // Show 1 slide on mobile
            slidesToScroll: 1, // Scroll 1 slide at a time on mobile
            arrows: false, // Hide the arrows on mobile
          },
        },
        {
          breakpoint: 1000, // Set a breakpoint for mobile devices
          settings: {
            slidesToShow: 3, // Show 1 slide on mobile
            slidesToScroll: 1, // Scroll 1 slide at a time on mobile
            arrows: false, // Hide the arrows on mobile
          },
        },
      ],
  };

  return (
    <div className="bg-black py-8 px-6 text-white">
      <div className="container mx-auto text-center">
        <h1 className={`${styles.nav_title} text-lg text-center`}>Brands I've Worked With</h1>
        <p className='md:text-lg text-sm leading-6'>Here are some of the incredible organizers and brands I had the pleasure of working with.</p>
        <div className="md:w-[85%] mx-auto md:my-10 my-6">
          <Slider {...settings}>
            {logos.map((logo, index) => (
                <div key={index} className="mx-2">
                    <img
                        src={require(`../images/${logo}`)} // Update the path to your image files
                        alt={`Brand Logo ${index + 1}`}
                        className="w-36 h-20 mx-4 my-4"
                    />
              </div>
            ))}
          </Slider>
        </div>
        <p className="md:text-lg text-sm leading-6">
        As Peculiar Beats, I had the honor of collaborating with a variety of event organizers and brands, helping create unforgettable experiences. From high-energy music festivals to corporate events and brand activations, Peculiar Beats provided the perfect beats for every occasion.        </p>
      </div>
    </div>
  );
};

export default Brands;
