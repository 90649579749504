import Header from './components/Header';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Brands from './components/Brands';

function App() {
  return (
    <>
      <Header/>
      <Home/>
      <Gallery/>
      <Brands/>
      <Contact/>
    </>
  );
}

export default App;
