import React, {useState, useEffect} from 'react'
import styles from '../css/navbar.module.css'

const Header = () => {
  const [navOpen, setNavOpen] = useState(false)
  const [show, setShow] = useState(true);

  useEffect(() => {
    const onScroll = e => {
      if(window.pageYOffset>40){
        setShow(false);
      }else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [show]) ;

  return (
    <>
    <nav class={`z-10 fixed w-full text-white py-2 border-b-2 border-b-cyan-700 ${!show ? styles.bg_show: ''}`}>
      <div class={`container px-2 sm:px-1 flex flex-wrap items-center justify-between mx-auto`}>
        <a href="/" class="flex items-center">
          <div  style={{width:"40px", height: "40px"}} className="mx-2 pt-1">
            <img src={require('../images/logo-official.png')} alt="Flowbite Logo" />
          </div>
          <span className={`${styles.nav_title} self-center text-md mt-1`}>Peculiar Beats</span>
        </a>
        <button onClick={()=>{setNavOpen(!navOpen)}} type="button" class="inline-flex p-1 items-center text-xs text-white focus:outline-none border-2 border-sky-300 rounded-lg">
          Quick Links
        </button>
      </div>
      <div class={`w-fit ml-auto mt-4 mr-4 absolute right-0 ${styles.bg_show} ${navOpen?'block':'hidden'}`}>
          <ul class="flex flex-col p-4 border border-gray-100 md:flex-row md:space-x-8 md:mt-0 text-xs md:border-0">
            <li>
              <a href="https://www.instagram.com/peculiar_beats/" target={'_blank'} className='flex'>
              <img src={require('../images/instagram.png')} style={{width:"20px", margin:"auto"}} alt="" />
              <p class="block py-2 pl-3 pr-4" aria-current="page">Instagram</p>
              </a>
            </li>
            <li>
              <a href="tel:+917619437950" target={'_blank'} className='flex'>
                <img src={require('../images/telephone.png')} style={{width:"20px", margin:"auto"}} alt="" />
                <p class="block py-2 pl-3 pr-4">Call Now</p>
              </a>
            </li>
            <li>
              <a href='https://wa.me/+917619437950' target={'_blank'} className='flex'>
                <img src={require('../images/whatsapp.png')} style={{width:"20px", margin:"auto"}} alt="" />
                <p class="block py-2 pl-3 pr-4">Whatsapp</p>
              </a>
            </li>
            <li>
              <a href='https://www.mixcloud.com/peculiar_beats/' target={'_blank'} className='flex'>
                <img src={require('../images/cloud-icon.png')} style={{width:"20px", margin:"auto"}} alt="" />
                <p class="block py-2 pl-3 pr-4">Mix cloud</p>
              </a>
            </li>
            <li>
              <a href='https://soundcloud.com/peculiar-beats' target={'_blank'} className='flex'>
                <img src={require('../images/soundcloud-icon.png')} style={{width:"20px", margin:"auto"}} alt="" />
                <p class="block py-2 pl-3 pr-4">Sound cloud</p>
              </a>
            </li>
          </ul>
        </div>
    </nav>
    </>
  )
}

export default Header;